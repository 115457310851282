import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import FullWidthImage from "../components/FullWidthImage";
import DisplayContentHeader from "../components/DisplayContentHeader";

import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

// eslint-disable-next-line
export const FourOhFourPageTemplate = ({
  title,
  subtitle,
  footer,
  images,
  options,
  summary,
  body,
}) => {
  let heroImage, footerImage, smallImage;
  if (images && images.hero) heroImage = getImage(images.hero) || images.hero;
  if (images && images.footer)
    footerImage = getImage(images.footer) || images.footer;
  if (images && images.smallImage) smallImage = images.smallImage;

  const showTitle = options.showTitle;

  return (
    <div>
      {heroImage ? (
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subtitle}
          imgPosition="center"
          showTitle={showTitle}
        />
      ) : null}
      {summary ? (
        <Helmet>
          <meta
            name="description"
            content={
              summary || `404 Error. You've reached a page that doesn't exist.`
            }
          />
        </Helmet>
      ) : null}

      <section className="section section--gradient">
        <div className="container">
          <div className="content">
            <div className="content">
              {smallImage ? (
                <figure className="image is-inline-block author-icon is-pulled-right pt-0 mt-0 is-hidden-mobile">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: smallImage,
                      alt: String(title + " " + subtitle).trim(),
                      className: "author-image",
                    }}
                  />
                </figure>
              ) : null}
              <DisplayContentHeader title={title} subtitle={subtitle} />
            </div>

            {body}
            <br clear="all" />
          </div>
        </div>
      </section>

      {footerImage ? (
        <FullWidthImage
          img={footerImage}
          subheading={footer}
          imgPosition="center"
          className="footer-image-text"
          showTitle={true}
        />
      ) : null}
    </div>
  );
};

FourOhFourPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  images: PropTypes.shape({
    herp: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    smallImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  options: PropTypes.shape({
    showTitle: PropTypes.bool,
  }),
  summary: PropTypes.string,
  body: PropTypes.string,
};

const FourOhFourPage = ({ data }) => {
  const { markdownRemark: post } = data;
  console.log(post);
  return (
    <Layout>
      <FourOhFourPageTemplate
        title={post.frontmatter.title}
        subtitle={post.frontmatter.titles.subtitle}
        footer={post.frontmatter.titles.footer || null}
        images={post.frontmatter.images}
        options={post.frontmatter.options}
        summary={post.frontmatter.summary}
        body={<HTMLContent content={post.html} />}
      />
    </Layout>
  );
};

FourOhFourPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FourOhFourPage;

export const fourOhFourPageQuery = graphql`
  query FourOhFourPage {
    markdownRemark(
      frontmatter: {
        advanced: {
          configKey: { eq: "404" }
          templateKey: { eq: "configuration" }
        }
      }
    ) {
      html
      frontmatter {
        title
        titles {
          subtitle
          footer
        }
        images {
          hero {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          smallImage {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
          footer {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
        options {
          showTitle
        }
        summary
      }
    }
  }
`;
